import styled from "styled-components"

const getButtonData = (size, props) => {
  const { theme } = props

  const defaultData = {
    width: 96,
    height: 40,
    fontSize: theme.button.medium.fontSize
  }

  if (!size) return defaultData

  switch (size) {
    case "small": {
      return defaultData
    }
    case "medium":
      return {
        width: 148,
        height: 48,
        fontSize: theme.button.medium.fontSize
      }
    case "large":
      return {
        width: 250,
        height: 80,
        fontSize: theme.button.large.fontSize
      }
    default: {
      return defaultData
    }
  }
}

export const Button = styled.button.attrs(props => getButtonData(props.size, props))`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  background-color: transparent;
  border: none;

  font-size: ${props => props.fontSize}em;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;

  cursor: pointer;

  display: block;
`

export const CtaButton = styled(Button)`
  min-width: ${props => props.width}px;
  min-height: ${props => props.height}px;

  background-color: ${props => props.theme.colours.main};
`

export const ImageButton = styled.button`
  width: 40px;
  height: 40px;

  background-image: url(${props => props.src});
  background-color: transparent;
  background-position: 50%;
  background-size: 100%;

  border: none;

  cursor: pointer;
`
