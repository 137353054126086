import { HashLink } from "react-router-hash-link"
import styled, { css } from "styled-components"

import downIcon from "../../assets/icons/icon-chevrondown-mustard.svg"
import rightIcon from "../../assets/icons/icon-chevronright.svg"
import upIcon from "../../assets/icons/icon-chevronup-mustard.svg"
import closeIcon from "../../assets/icons/icon-close.svg"
import { ImageButton } from "../../styles/button"

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px 0;

  background-color: #fff;
`

export const CloseButton = styled(ImageButton).attrs(() => ({
  src: closeIcon
}))`
  position: absolute;
  top: 15px;
  right: 15px;

  display: none;

  width: 30px;
  height: 30px;

  margin-bottom: 20px;
`

const linkStyling = css`
  color: #000;
  line-height: 1.3;
  font-weight: 700;
  font-size: 0.9em;
  font-family: ${props => props.theme.fonts.secondary};
  text-transform: capitalize;
  text-decoration: none;
  text-align: left;

  :hover {
    color: ${props => props.theme.colours.main};
  }

  ${props =>
    props.$isSelected &&
    css`
      color: ${() => props.theme.colours.main};
    `}

  @media (max-width: ${props => props.theme.sizes.navigation.mobile}px) {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 40px;
    font-weight: 400;
    font-size: 1.1em;
    background-image: url(${rightIcon});
    background-repeat: no-repeat;
    background-position: 90% 20px;
    background-size: 15px;
  }
`

export const NavLink = styled(HashLink)`
  ${linkStyling};
`

export const Menu = styled.div`
  position: absolute;
  top: 75px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${props =>
    props.isVisible
      ? css`
          opacity: 1;
          transition: opacity 0.2s ease-in-out;
        `
      : css`
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
          pointer-events: none;
        `}

  ${NavLink} {
    display: none;
  }

  @media (max-width: ${props => props.theme.sizes.navigation.mobile}px) {
    top: 60px;
    right: 0;

    align-items: center;

    width: 100vw;
    height: 100vh;

    background-color: #fff;

    ${NavLink} {
      display: initial;
    }

    ${LinkContainer} {
      align-items: flex-start;

      padding-top: 20px;
      width: 100%;

      background-color: transparent;
    }

    ${CloseButton} {
      display: initial;
    }
  }
`

export const LanguageLink = styled.button`
  display: none;

  width: 110px;

  padding-left: 15px;

  background-color: transparent;
  background-image: url(${props => props.imgurl});
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 20px;

  border: none;

  color: ${props => (props.isActive ? props.theme.colours.main : "#fff")};
  font-family: ${props => props.theme.fonts.secondary};
  font-weight: 400;
  font-size: 0.9em;
  line-height: 2;
  text-align: left;

  cursor: pointer;

  :hover {
    color: ${props => props.theme.colours.main};
  }
`

export const LanguageHeader = styled.div`
  width: 135px;

  background-image: url(${downIcon});
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 28px;

  user-select: none;
  cursor: pointer;

  h4 {
    ${linkStyling};
  }
`

export const LanguageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${props =>
    props.areLanguagesExpanded &&
    css`
      ${LanguageLink} {
        display: initial;
      }

      ${LanguageHeader} {
        margin-bottom: 8px;

        background-image: url(${upIcon});
      }
    `}
`

export const Logo = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
`

export const ContactWrapper = styled.div`
  padding: 0 40px;
  width: 100%;
`
