/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/forbid-prop-types */
import React from "react"
import { Link, useLocation } from "react-router-dom"

import * as S from "./style"
import logo from "../../assets/images/logo.png"
import { useScreenSize } from "../../utils/hooks"
import ContactButton from "../ContactButton"
import LoginButton from "../LoginButton"
import Menu from "../Menu"

const NavBar = ({ navLinks, contactLabel, loginLabel, brandLoginOptions, handleBodyScrollLockChange }) => {
  const location = useLocation()
  const screenSize = useScreenSize()

  const [isMenuVisible, setIsMenuVisible] = React.useState(false)

  const handleMenuCloseButtonClick = () => {
    handleBodyScrollLockChange(false)
    setIsMenuVisible(false)
  }

  const handleMenuButtonClick = () => {
    if (isMenuVisible) {
      handleMenuCloseButtonClick()
    } else {
      setIsMenuVisible(true)
      if (screenSize.width.lessThanOrEqualTo(768)) handleBodyScrollLockChange(true)
    }
  }

  const checkForPathMatch = link => location.pathname.startsWith(link)

  return (
    <S.NavBar>
      <S.NavBarInner>
        <Link
          to="/"
          onClick={() => {
            document.documentElement.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth"
            })
            setIsMenuVisible(false)
          }}
        >
          <S.Logo src={logo} alt="Logo" />
        </Link>
        <S.FlexSpacer />
        {screenSize.width.greaterThanOrEqualTo(901) &&
          navLinks.map(link => (
            <S.WebLink
              key={link.text}
              to={link.to}
              smooth={link.smoothScroll}
              {...(link.matchPath && { $isSelected: checkForPathMatch(link.matchPath) })}
            >
              {link.text}
            </S.WebLink>
          ))}
        {/* {screenSize.width.greaterThanOrEqualTo(768) && <RegisterButton options={registerOptions} label={registerLabel} to={`/${registerSlug}`} />} */}
        {screenSize.width.greaterThanOrEqualTo(768) && <ContactButton label={contactLabel} />}
        <LoginButton options={brandLoginOptions} label={loginLabel} isVisible={isMenuVisible} handleCloseMenu={handleMenuCloseButtonClick} />
        {screenSize.width.lessThan(768) && (
          <>
            <S.MenuButton isOpen={isMenuVisible} onClick={handleMenuButtonClick} />
            <Menu
              navLinks={navLinks}
              checkFunction={checkForPathMatch}
              isVisible={isMenuVisible}
              contactLabel={contactLabel}
              handleClose={handleMenuCloseButtonClick}
            />
          </>
        )}
      </S.NavBarInner>
    </S.NavBar>
  )
}

export default NavBar
