/* eslint-disable react/forbid-prop-types */
import PropTypes from "prop-types"
import React from "react"
import ReactMarkdown from "react-markdown"
import { Link } from "react-router-dom"

import * as S from "./style"
import { ReactComponent as FacebookSVG } from "../../assets/icons/social/facebook.svg"
import { ReactComponent as InstagramSVG } from "../../assets/icons/social/instagram.svg"
import { ReactComponent as TwitterSVG } from "../../assets/icons/social/twitter.svg"

const Footer = ({ legalLinks, ourSitesLinks, socialLinks, navLinks, translations }) => (
  <S.Footer>
    <S.Inner>
      <S.Section>
        <h3>{translations.navigation}</h3>
        <ul>
          {navLinks.map(link => (
            <li key={link.text}>
              <Link to={link.to}>{link.text}</Link>
            </li>
          ))}
        </ul>
      </S.Section>
      <S.Section>
        <h3>{translations.legal}</h3>
        <ul>
          {legalLinks
            .sort((a, b) => a.label.localeCompare(b.label))
            .map(({ slug, label }, index) => (
              <li key={index}>
                <Link to={`/${slug}`}>{label}</Link>
              </li>
            ))}
        </ul>
      </S.Section>
      <S.Section>
        <h3>{translations.ourSites}</h3>
        <ReactMarkdown>{ourSitesLinks}</ReactMarkdown>
      </S.Section>
      {socialLinks && (
        <S.Section>
          {socialLinks[0] && (
            <S.SocialMediaLink href={socialLinks[0].url} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FacebookSVG alt="facebook" />
            </S.SocialMediaLink>
          )}
          {socialLinks[1] && (
            <S.SocialMediaLink href={socialLinks[1].url} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <TwitterSVG alt="twitter" />
            </S.SocialMediaLink>
          )}
          {socialLinks[2] && (
            <S.SocialMediaLink href={socialLinks[2].url} target="_blank" rel="noopener noreferrer" aria-label="Instagram">
              <InstagramSVG alt="instagram" />
            </S.SocialMediaLink>
          )}
        </S.Section>
      )}
    </S.Inner>
  </S.Footer>
)

Footer.propTypes = {
  legalLinks: PropTypes.objectOf(PropTypes.any).isRequired,
  ourSitesLinks: PropTypes.objectOf(PropTypes.any).isRequired,
  socialLinks: PropTypes.objectOf(PropTypes.any).isRequired,
  navLinks: PropTypes.arrayOf(PropTypes.any).isRequired,
  translations: PropTypes.objectOf(PropTypes.any).isRequired
}

Footer.defaultProps = {}

export default Footer
