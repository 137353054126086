/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React from "react"

import * as S from "./style"
import ContactButton from "../ContactButton"

const Menu = ({ isVisible, checkFunction, navLinks, contactLabel, handleClose }) => {
  const createNavLink = link => (
    <S.NavLink key={link.text} to={link.to} smooth={link.smoothScroll} {...(link.matchPath && checkFunction(link.matchPath))} onClick={handleClose}>
      {link.text}
    </S.NavLink>
  )

  return (
    <S.Menu isVisible={isVisible}>
      <S.LinkContainer>{navLinks.map(link => createNavLink(link))}</S.LinkContainer>
      <S.ContactWrapper>
        <ContactButton label={contactLabel} size="full" onClick={handleClose} />
      </S.ContactWrapper>
    </S.Menu>
  )
}

export default Menu
