import styled, { css } from "styled-components"

export const ToggleInput = styled.button`
  display: flex;
  align-items: center;

  gap: 16px;

  border: none;
  background: none;

  ${({ $disabled }) =>
    $disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          cursor: pointer;
        `}
`

export const Toggle = styled.div`
  position: relative;

  min-width: 34px;
  min-height: 14px;

  border-radius: 8px;
  margin: 0;

  ${({ $isOn, $onColour, $offColour }) =>
    $isOn
      ? css`
          background-color: ${$onColour};
        `
      : css`
          background-color: ${$offColour};
        `}

  transition: background-color 0.2s ease-in-out;
`

export const Pin = styled.div`
  position: absolute;

  width: 20px;
  height: 20px;

  top: -3px;

  ${({ $isOn, $onColour, $offColour }) =>
    $isOn
      ? css`
          transform: translateX(100%);
          background-color: ${$onColour};
        `
      : css`
          transform: translateX(calc(0% - 6px));
          background-color: ${$offColour};
        `}

  transition: transform 0.2s ease-in-out;

  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 3px 0px;

  border-radius: 100%;
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
`

export const Title = styled.p`
  letter-spacing: 0.5px;
  text-align: left;

  ${({ $colour }) => css`
    color: ${$colour};
  `}
`

export const Body = styled.p`
  ${({ $colour }) => css`
    color: ${$colour};
  `}

  text-align: left;
`
