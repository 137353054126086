/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React from "react"

import * as S from "./style"

const ContactButton = ({ label, size, onClick }) => {
  return (
    <S.ContactButton to="/#contact" size={size} onClick={onClick} gtmData={{ action: "contact", label: "navigate to form" }}>
      {label}
    </S.ContactButton>
  )
}

export default ContactButton
