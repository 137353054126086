/* eslint-disable react/prop-types */
import React from "react"
import { Link } from "react-router-dom"
import { useTheme } from "styled-components"

import * as S from "./style"
import { pushToDataLayer } from "../../helpers/gtmManager"

export const getSizeProps = (size, theme) => {
  const defaultData = {
    width: 90,
    height: 40,
    fontSize: theme.button.medium.fontSize
  }

  if (!size) return defaultData

  switch (size) {
    case "small": {
      return defaultData
    }
    case "medium":
      return {
        width: 148,
        height: 48,
        fontSize: theme.button.medium.fontSize
      }
    case "large":
      return {
        width: 250,
        height: 70,
        fontSize: theme.button.large.fontSize
      }
    case "full": {
      return {
        width: "100%",
        height: 48,
        fontSize: theme.button.medium.fontSize
      }
    }
    default: {
      return defaultData
    }
  }
}

const Button = ({ className, to, isAltColour, icon, size, isDisabled, gtmData, onClick, children, onMouseLeave }) => {
  let props = {}

  if (to) {
    if (to[0] === "/") {
      props = { as: Link, to }
    } else {
      props = {
        as: "a",
        href: to,
        target: "_blank",
        rel: "noopener noreferrer"
      }
    }
  }

  const theme = useTheme()

  return (
    <S.Button
      {...(onMouseLeave && { onMouseLeave })}
      className={className}
      {...(to && props)}
      {...getSizeProps(size, theme)}
      $isAltColour={isAltColour}
      icon={icon}
      disabled={isDisabled}
      onClick={() => {
        if (onClick) {
          onClick()
        }

        if (gtmData) {
          pushToDataLayer(gtmData)
        }
      }}
    >
      {children}
    </S.Button>
  )
}

export default Button
