/* eslint-disable react/prop-types */
import * as React from "react"
import { Helmet } from "react-helmet"
import { Outlet, useLoaderData, useLocation } from "react-router-dom"
import { ThemeProvider } from "styled-components"

import * as S from "./style"
import favicon from "../../assets/favicon/leovegas/favicon.ico"
import Footer from "../../components/Footer"
import ManagedCookieConsent from "../../components/ManagedCookieConsent"
import NavBar from "../../components/NavBar"
import { initialize } from "../../helpers/gtmManager"
import GlobalStyle from "../../styles/globalStyle"
import { themes } from "../../styles/themes"
import { useCookieConsent } from "../../utils/hooks"

const { REACT_APP_THEME: theme } = process.env

const ScrollToTop = () => {
  const { pathname, hash } = useLocation()

  React.useEffect(() => {
    if (hash) {
      return
    }

    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    })
  }, [pathname])

  return null
}

export const RootLayout = () => {
  const [lockBodyScroll, setLockBodyScroll] = React.useState(false)

  const { seo, navLinks, translations, brandLoginOptions, footer } = useLoaderData()
  const { showConsent, shouldLoadGtm, accept } = useCookieConsent()

  React.useEffect(() => {
    if (shouldLoadGtm) {
      initialize()
    }
  }, [shouldLoadGtm])

  const location = useLocation()
  const lastHash = React.useRef("")

  React.useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1) // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document.getElementById(lastHash.current)?.scrollIntoView({ behavior: "smooth", block: "start" })
        lastHash.current = ""
      }, 100)
    }
  }, [location])

  const { fontsUrl } = themes[theme]

  const handleBodyScrollLockChange = value => setLockBodyScroll(value)

  const { cookieConsentContent } = translations

  return (
    <>
      <Helmet>
        <title data-react-helmet="true">{seo.title}</title>
        <meta name="description" content={seo.description} data-react-helmet="true" />
        <link rel="stylesheet" href={fontsUrl} />
        <link rel="icon" href={favicon} />
        <script>
          {`window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments);}
        if (localStorage.getItem('consentMode') === null){
          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'personalization_storage': 'denied',
            'functionality_storage': 'denied',
            'security_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied'
          })
        } else {
          gtag ('consent', 'default', JSON.parse(localStorage.getItem('consentMode')))
        }`}
        </script>
      </Helmet>
      <S.RootContainer>
        <ThemeProvider theme={themes[theme]}>
          <GlobalStyle $lockBodyScroll={lockBodyScroll} />
          <ScrollToTop />
          <NavBar
            navLinks={navLinks}
            // registerLabel={translations.contact}
            // registerSlug="#contact"
            contactLabel={translations.contact}
            loginLabel={translations.login}
            // registerOptions={registrationForm}
            brandLoginOptions={brandLoginOptions}
            handleBodyScrollLockChange={handleBodyScrollLockChange}
          />
          <Outlet />
          {showConsent && (
            <ManagedCookieConsent
              onAccept={accept}
              disclaimer={cookieConsentContent.disclaimer}
              cookiePolicyLink={cookieConsentContent.cookiePolicyLink}
              acceptButtonText={cookieConsentContent.buttons.accept}
              allowAllButtonText={cookieConsentContent.buttons.allowAll}
              confirmButtonText={cookieConsentContent.buttons.confirm}
              manageButtonText={cookieConsentContent.buttons.manage}
              necessary={cookieConsentContent.necessary}
              functional={cookieConsentContent.functional}
              analytics={cookieConsentContent.analytics}
              marketing={cookieConsentContent.marketing}
            />
          )}
          <Footer
            socialLinks={footer.socialLinks}
            ourSitesLinks={footer.ourSitesLinks}
            legalLinks={footer.legalLinks}
            navLinks={navLinks}
            translations={translations}
          />
        </ThemeProvider>
      </S.RootContainer>
    </>
  )
}
